import { lazy } from "react";

const VisitorLogList = lazy(() => import("./visitorLogList"));

const visitorLogRoutes = [
  {
    path: "/visitor-log",
    element: <VisitorLogList />,
  }
];

export default visitorLogRoutes;
