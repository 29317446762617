import { lazy } from "react";

const VisitorTypeList = lazy(() => import("./visitorTypeList"));

const visitorTypeRoutes = [
  {
    path: "/visitor-type",
    element: <VisitorTypeList />
  }
];

export default visitorTypeRoutes;
