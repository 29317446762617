export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200
export const currency = '$'
export const baseURL = ''

export const ports = {
    "unit":"5952",
    "home":"5952",
    "access":"5951",
    "employee":"5953",
    "document":"5955",
    "image":"5955",
    // "payroll":"5951",
    "payroll":"5954",
    "report":"5959",
    "inventory":"5956",
    "visitor":"5957",
};

const getSession = (sessionName) => {
    return sessionName ? window.localStorage.getItem(sessionName) : false
  }
  
const unitData = getSession('unitToken');
const unitTok = (unitData != null || unitData != undefined) ? unitData.replace(/"/g, '') : '';

export const docPath = baseURL+ports.employee+'/images/docs/'
export const profileImages = baseURL+ports.employee+'/images/profiles/'
export const employeePath =`units/${unitTok}/employee/`
export const studentPath =`units/${unitTok}/student/`
export const syllabus =`units/${unitTok}/syllabus`
export const event =`units/${unitTok}/event`
export const visitorpath =`units/${unitTok}/visitor`
export const unitPath =`units/unitinfo/`
export const imagePath = 'https://scholerplus.s3.ap-south-1.amazonaws.com/'
// export const imagePath = baseURL+ports.image+'/images/'
export const draftStatus = ['purchaseorder', 'goodsreceived', 'assembleorder', 'assembleorderreceipt', 'quotation', 'sales', 'delivery', 'invoice']
export const productKey = 'BT'
export const serviceStatusCode = {
    "backlog": 1,
    "toDo": 2,
    "inProgress": 3,
    "pendingPayment": 4,
    "complete": 5,
    "cancel": 6,
    "pickUp": 7,
    "delivery": 8,
    "close": 9,
    "review": 10,
    "verified": 11
}
export const currencySymbol= "₹";