import React from 'react';
import AuthGuard from 'app/auth/AuthGuard'

import sessionsRoutes from "app/views/sessions/sessionsRoutes";
import dataTableRoute from "app/views/dataTable/dataTableRoute";
import invoiceRoutes from "app/views/app/invoice/invoiceRoutes";
import inboxRoutes from "app/views/app/inbox/inboxRoutes";
import chatRoutes from "app/views/app/chat/chatRoutes";
import calendarRoutes from "app/views/app/calendar/calendarRoutes";
import taskManagerRoutes from "app/views/app/task-manager/taskManagerRoutes";
import contactRoutes from "app/views/app/contact/contactRoutes";
import Error404 from "app/views/sessions/Error";
import dashboardRoutes from "app/views/dashboard/dashboardRoutes";
import chartsRoute from "app/views/charts/chartsRoute";
import { Navigate, Outlet } from 'react-router-dom'
import GullLayout from 'app/GullLayout/GullLayout';
import departmentRoutes from 'app/views/masters/department/department-route';
import designationRoutes from 'app/views/masters/designation/designation-route';
import employeeRoutes from 'app/views/employee/employeeRoutes';
import shiftRoutes from 'app/views/masters/shift/shift-route';
import qualificationRoutes from 'app/views/masters/qualification/qualification-route';
import holidaysRoutes from 'app/views/holidays/holidays-route';
import employeeAttendanceRoutes from 'app/views/employee-attendance/employee-attendance-route';
import leaveSettingsRoutes from 'app/views/leave-settings/leave-settings-route';
import leaveTypeRoutes from 'app/views/masters/leave-type/leave-type-route';
import employeeLeaveRoutes from 'app/views/employee-leave/employee-leave-route';
import EmployeeAttendanceReportRoutes from 'app/views/report/employeeAttendanceReport/employee-attendance-report-route';
import employeeReportRoutes from 'app/views/report/employeeReport/employee-report-route';
import proofTypeRoutes from 'app/views/masters/proof-type/proof-type-route';
import employeePromotionRoutes from 'app/views/employee-promotion/employee-promotion-route';
import admissionRoutes from 'app/views/student-admission/admission-route';
import StudentAttendanceRoutes from 'app/views/student-attendance/studentAttendance-route';
import examRoutes from 'app/views/exam/examRoute';
import homeWorkRoutes from 'app/views/home-work/home-work-route';
import batchRoutes from 'app/views/masters/batch/batch-route';
import classRoutes from 'app/views/masters/class/class-route';
import sectionRoutes from 'app/views/masters/section/section-route';
import courseRoutes from 'app/views/masters/course/course-route';
import examTypeRoutes from 'app/views/masters/exam-type/exam-type-route';
import SubjectRoutes from 'app/views/masters/subject/subjectRoute';
import PeriodTimeRoutes from 'app/views/masters/period-time/period-time-route';
import homeWorkTypeRoutes from 'app/views/masters/home-work-type/home-work-type-route';
import enrollmentRoutes from 'app/views/masters/enrollment/enrollment-route';
import gradeRoutes from 'app/views/masters/grade/grade-route';
import markSheetRoutes from 'app/views/marksheet/marksheet-route';
import subjectEnrollmentRoutes from 'app/views/subjectEnrollment/subject-enrollment-route';
import subjectTeachersRoutes from 'app/views/subject-teachers/subject-teachers-route';
import timeTableRoutes from 'app/views/time-table/time-table-route';
import roleRoutes from 'app/views/masters/role/role-route';
import accessRoutes from 'app/views/masters/access/access-route';
import feesTypeRoutes from 'app/views/masters/fees-type/fees-type-route';
import feesEnrollmentRoutes from 'app/views/fees-enrollment/fees-enrollment-route';
import studentFeesPaymentRoutes from 'app/views/student-fees-payment/student-fees-payment-route';
import UnitRoutes from 'app/views/unit/unit-route';
import PagePermissionRoutes from 'app/views/page-permission/page-permission-route';
import circularRoutes from 'app/views/circular/circular-route';
import schoolInfoRoutes from 'app/views/school-info/school-info-route';

import KnowledgeMaterialRoutes from 'app/views/knowledge-material/knowledge-material-route';
import EventRoutes from 'app/views/event/event-route';
import EnquiryRoutes from 'app/views/enquiry/enquiry-route';
import DailyExamMarkSheetRoutes from 'app/views/daily-exam-marksheet/daily-exam-marksheet-route';
import UnitTypeRoutes from 'app/views/masters/unit-type/unit-type-route';
import studentLeaveRoutes from 'app/views/student-leave/student-leave-route';
import studentListRoutes from 'app/views/student-list/student-list-route';
import employeStatusRoutes from 'app/views/employee-status/employee-status-route';
import BookRoutes from 'app/views/masters/book/book-route';
import BookTypeRoutes from 'app/views/masters/book-type/book-type-route';
import KnowledgeBookListRoutes from 'app/views/masters/knowledge-book/knowledge-book-route';
import SyllabusRoutes from "app/views/syllabus/syllabus-route";
import QuizRoutes from 'app/views/quiz/quiz-route';
import createQuizRoutes from 'app/views/quiz/create-quiz/create-quiz-route';
import PettyCashRoutes from "app/views/petty-cash/petty-cash-route";


import purchaseRoutes from 'app/views/purchase/purchase-route';
import goodsReceivedRoutes from 'app/views/goodsReceived/goods-received-route';
import supplierRoutes from 'app/views/supplier/supplier-route';
import warehouseRoutes from 'app/views/warehouse/warehouse-route';
import locationRoutes from 'app/views/location/location-route';
import InventoryRoutes from 'app/views/inventory/inventory-list-route';
import InventoryTransferRoutes from 'app/views/inventory-transfer/inventory-transfer-list-route';
import inventoryAdjustmentRoutes from 'app/views/inventoryAdjustment/inventory-adjustment-route';
import QuotationRoutes from 'app/views/quotation/quotation-route';
import SalesRoutes from 'app/views/sales/sales-route';
import DeliveryRoutes from 'app/views/delivery/delivery-route';
import ReceiptRoutes from 'app/views/receipt-list/receipt-route';
import productRoutes from 'app/views/product/product-route';
import InvoiceRoutes from 'app/views/invoice/invoice-route';

import visitorTypeRoutes from 'app/views/visitor-type/visitor-type-route';
import visitorRoutes from 'app/views/visitor/visitor-route';
import visitorLogRoutes from 'app/views/visitor-log/visitor-log-route';
















export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard >
                    <GullLayout>
                        <Outlet />
                    </GullLayout>
                </AuthGuard>
            ),
            children: [...dataTableRoute, ...invoiceRoutes, ...inboxRoutes, ...calendarRoutes,
            ...taskManagerRoutes, ...contactRoutes, ...chatRoutes, ...dashboardRoutes, ...chartsRoute,
            ...departmentRoutes, ...designationRoutes, ...employeeRoutes, ...shiftRoutes, ...qualificationRoutes, 
            ...holidaysRoutes, ...employeeAttendanceRoutes, ...leaveSettingsRoutes, ...leaveTypeRoutes,
            ...employeeLeaveRoutes, ...EmployeeAttendanceReportRoutes, ...employeeReportRoutes,...proofTypeRoutes,
            ...employeePromotionRoutes,...admissionRoutes,...StudentAttendanceRoutes,...examRoutes,...homeWorkRoutes,
            ...batchRoutes,...classRoutes,...sectionRoutes,...courseRoutes,...examTypeRoutes,...SubjectRoutes,...PeriodTimeRoutes,
            ...homeWorkTypeRoutes,...enrollmentRoutes, ...gradeRoutes, ...markSheetRoutes, ...subjectEnrollmentRoutes, ...timeTableRoutes, ...subjectTeachersRoutes,
            ...roleRoutes, ...accessRoutes, ...feesTypeRoutes, ...feesEnrollmentRoutes, ...studentFeesPaymentRoutes, ...UnitRoutes, ...PagePermissionRoutes, ...circularRoutes,
            ...schoolInfoRoutes,
            ...roleRoutes, ...accessRoutes, ...feesTypeRoutes, ...feesEnrollmentRoutes, ...studentFeesPaymentRoutes, ...UnitRoutes, ...PagePermissionRoutes, ...circularRoutes ,...KnowledgeMaterialRoutes ,
            ...EventRoutes,...EnquiryRoutes ,...DailyExamMarkSheetRoutes, ...UnitTypeRoutes, ...studentLeaveRoutes,
            ...studentListRoutes,...employeStatusRoutes ,...BookRoutes , ...BookTypeRoutes , ...KnowledgeBookListRoutes,
           

            ...SyllabusRoutes ,...QuizRoutes,...createQuizRoutes, ...PettyCashRoutes, ...purchaseRoutes,...goodsReceivedRoutes,...supplierRoutes,...warehouseRoutes,...locationRoutes,...InventoryRoutes,
            ...InventoryTransferRoutes,...inventoryAdjustmentRoutes,...QuotationRoutes,...SalesRoutes,...DeliveryRoutes,...ReceiptRoutes,...productRoutes,...InvoiceRoutes,...visitorTypeRoutes,...visitorRoutes,...visitorLogRoutes,
            ]
        },
        ...sessionsRoutes,
        {
            path: '/',
            // element: <Navigate to="dashboard/default" />,
            element: <Navigate to="dashboard" />,
        },
        {
            path: '*',
            element: <Error404 />,
        },
    ]

    return all_routes
}
