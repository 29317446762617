
import moment from "moment";
import { jsPDF } from "jspdf";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import 'jspdf-autotable'
import { NotificationContainer, NotificationManager, } from "react-notifications";
import { currencySymbol } from "./constant";

const showMessage = (type, msg, title = null) => {
  switch (type) {
    case 'info':
      NotificationManager.info(msg, title);
      break;
    case 'success':
      NotificationManager.success(msg, title);
      break;
    case 'warning':
      NotificationManager.warning(msg, title);
      break;
    case 'error':
      NotificationManager.error(msg, title);
      break;
  }
}

function validate(checkData) {
  let isEmpty = false;
  if (Array.isArray(checkData)) {
    (checkData || []).forEach(ele => {
      for (let key in ele) {
        if (ele[key] === undefined || ele[key] === null || ele[key] === '' ) {
          isEmpty = true;
        }
      }
    });
  }
  return isEmpty;
}


const todayDate = (format = "DD-MM-YYYY") => {
  return moment().format(format);

}
const dateConversion = (date, format = "DD-MM-YYYY") => {
  return date ? moment(date).format(format) : "";

}

const decimalAmount = (data) => {
  return data ? `${parseFloat(data).toFixed(2)}` : '0.00';
}


const timeConversion = (time, format = "HH:mm:ss") => {
  return time ? moment(time, "HH:mm:ss").format(format) : "";
}

const export2pdf = (headers, data, title, name) => {
  //sample format for PDF
  /*  const headers = [['ID','Name','Age']];
      const data = [
          [1, 'John Doe', 30],
          [2, 'Jane Smith', 25],
          [3, 'Bob Johnson', 40]
      ];
      const title = 'Sample PDF Export';
      const name = 'sample'; */

  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait";
  const marginLeft = 20;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  let content = {
    startY: 50,
    head: headers,
    body: data
  };

  doc.text(title, marginLeft, 20);
  doc.autoTable(content);
  doc.save(`${name}.pdf`)
}

const removeNullKeysFromObj = (obj) => {
  let result = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, value.filter(v => {
          return Object.keys(v).filter((o) => {
            return v[o] === null && delete v[o]
          })
        })];
      } else {
        return [key, value];
      }
    }).filter(([_, v]) => v !== null)
  );
  return result
}

const isBeforeDate = (date, date1) => {
  return moment(date).isBefore(date1);
}

const uploadFileType = () => {
  const req = [
    {
      "fileTypeId": 1,
      "fileTypeName": "Experience Letter"
    },
    {
      "fileTypeId": 2,
      "fileTypeName": "Offer Letter"
    },
    {
      "fileTypeId": 3,
      "fileTypeName": "Reliving Letter"
    },
    {
      "fileTypeId": 4,
      "fileTypeName": "Certificate"
    },
  ]
  return req;
}

const amountFormat = (data) => {
  // return data ? `${currencySymbol} ${parseFloat(data).toFixed(2)}` : '-';
  return data ? `${parseFloat(data).toFixed(2)}` : '0.00';
}

const experienceCalculator = (date) => {
  var b = moment([dateConversion(date, "YYYY, MM, DD")]);
  var a = moment([dateConversion(new Date(), "YYYY, MM, DD")]);

  var years = a.diff(b, 'year');
  b.add(years, 'years');

  var months = a.diff(b, 'months');
  b.add(months, 'months');

  var days = a.diff(b, 'days');

  const experienceYears = years + ' Yrs '
  return experienceYears;
}

const firstLetterCaptial = (string) => {
  const str = string.toLowerCase();
  return str.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}

const formatStr = (string) => {
  const str = string.toLowerCase();
  return str.replace(/(\s\w)/g, m => m.toUpperCase()).replace(/\s+/g, '');
}

const removeSpaceBtwStr = (str) => {
  const result = str.replace(/ +/g, "");
  return result;
}

const subtractvalues = (val1, val2) => {
  return val1 && val2 ? parseInt(val1) - parseInt(val2) : "0"
}

const additionvalues = (val1, val2) => {
  return parseInt(val1 ? val1 : 0) + parseInt(val2 ? val2 : 0)
}

const exportToExcel = (apiData, fileName) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

const pagePermission = (data, parentName, pageName) => {
  let returnAccess = {};
  (global?.pageItems || []).forEach(ele => {
    if (ele.name === parentName && ele.children && ele.children.length) {
      ele.children.forEach(o => {
        if (o.name === pageName && o.children && o.children.length) {
          o.children.map(itm => {
            returnAccess[itm.accessName] = global?.roleId == 1 ? true : itm.accessGiven
          })
          /* data.viewOption = o.children?.[0]?.accessGiven || false
          data.addOption = o.children?.[1]?.accessGiven || false
          data.updateOption = o.children?.[2]?.accessGiven || false
          data.deleteOption = o.children?.[3]?.accessGiven || false */
        }
      });
    }
    else {
      if (ele.name === pageName && ele.children && ele.children.length) {
        ele.children.map(itm => {
          returnAccess[itm.accessName] = global?.roleId == 1 ? true : itm.accessGiven
        })
        /* data.viewOption = ele.children?.[0]?.accessGiven || false
        data.addOption = ele.children?.[1]?.accessGiven || false
        data.updateOption = ele.children?.[2]?.accessGiven || false
        data.deleteOption = ele.children?.[3]?.accessGiven || false */
      }
    }
  });
  return returnAccess
}

function validateEmail(email) {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email);
}

function validatePhoneNo(phone) {
  var phoneNum = phone.replace(/[^\d]/g, '');
  if (phoneNum.length == 10) {
    return true
  }
  else return false
}

export {
  showMessage,
  validateEmail,
  subtractvalues,
  validatePhoneNo,
  exportToExcel,
  additionvalues,
  formatStr,
  todayDate,
  firstLetterCaptial,
  isBeforeDate,
  uploadFileType,
  removeNullKeysFromObj,
  removeSpaceBtwStr,
  pagePermission,
  dateConversion,
  experienceCalculator,
  amountFormat,
  timeConversion,
  export2pdf,
  decimalAmount,
  validate
};