const initialState = {
  visitorList: []  
}

const VisitorReducer = function (state = initialState, action) {  
  switch (action.type) {
    case "GET_VISITOR_SUCCESS": {             
      return {
        ...state,
        getVisitorSuccess: true,
        getVisitorList: action.payload.data,
      };
    }
    case "GET_VISITOR_FAILURE": {
      return {
        ...state,
        getVisitorFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_GET_VISITOR": {
      return {
        ...state,
        getVisitorSuccess: false,
        getVisitorFailure: false,
        getVisitorList: [],
        errorMessage: false
      };
    }
    
    case "CREATE_VISITOR_SUCCESS": {             
      return {
        ...state,
        createVisitorSuccess: true,
        createVisitorData: action.payload.data,
      };
    }
    case "CREATE_VISITOR_FAILURE": {
      return {
        ...state,
        createVisitorFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_CREATE_VISITOR": {
      return {
        ...state,
        createVisitorSuccess: false,
        createVisitorFailure: false,
        createVisitorData: false,
        errorMessage: false         
      };
    }

    case "UPDATE_VISITOR_SUCCESS": {             
      return {
        ...state,
        updateVisitorSuccess: true,
        updateVisitorData: action.payload.data,
      };
    }
    case "UPDATE_VISITOR_FAILURE": {
      return {
        ...state,
        updateVisitorFailure: true,
        errorMessage: action.errorMessage
      };
    }
    case "RESET_UPDATE_VISITOR": {
      return {
        ...state,
        updateVisitorSuccess: false,
        updateVisitorFailure: false,
        updateVisitorData: false,
        errorMessage: false         
      };
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default VisitorReducer

