const initialState = {
    currencyList: []  
  }
  
  const CurrencyReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_CURRENCY_SUCCESS": {             
        return {
          ...state,
          getCurrencySuccess: true,
          getCurrencyList: action.payload.data,
        };
      }
      case "GET_CURRENCY_FAILURE": {
        return {
          ...state,
          getCurrencyFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_CURRENCY": {
        return {
          ...state,
          getCurrencySuccess: false,
          getCurrencyFailure: false,
          getCurrencyList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_CURRENCY_SUCCESS": {             
        return {
          ...state,
          createCurrencySuccess: true,
          createCurrencyData: action.payload.data,
        };
      }
      case "CREATE_CURRENCY_FAILURE": {
        return {
          ...state,
          createCurrencyFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_CURRENCY": {
        return {
          ...state,
          createCurrencySuccess: false,
          createCurrencyFailure: false,
          createCurrencyData: false,
        };
      }
      case "CREATE_USER_SUCCESS": {             
        return {
          ...state,
          createUserSuccess: true,
          createUserData: action.payload.data,
        };
      }
      case "CREATE_USER_FAILURE": {
        return {
          ...state,
          createUserFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_USER": {
        return {
          ...state,
          createUserSuccess: false,
          createUserFailure: false,
          createUserData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_CURRENCY_SUCCESS": {             
        return {
          ...state,
          updateCurrencySuccess: true,
          updateCurrencyData: action.payload.data,
        };
      }
      case "UPDATE_CURRENCY_FAILURE": {
        return {
          ...state,
          updateCurrencyFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_CURRENCY": {
        return {
          ...state,
          updateCurrencySuccess: false,
          updateCurrencyFailure: false,
          updateCurrencyData: false,
        }
      }
      case "UPDATE_USER_SUCCESS": {             
        return {
          ...state,
          updateUserSuccess: true,
          updateUserData: action.payload.data,
        };
      }
      case "UPDATE_USER_FAILURE": {
        return {
          ...state,
          updateUserFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_USER": {
        return {
          ...state,
          updateUserSuccess: false,
          updateUserFailure: false,
          updateUserData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default CurrencyReducer
  
  