const initialState = {
    visitorTypeList: []  
  }
  
  const VisitorTypeReducer = function (state = initialState, action) {  
    switch (action.type) {
      case "GET_VISITOR_TYPE_SUCCESS": {             
        return {
          ...state,
          getVisitorTypeSuccess: true,
          getVisitorTypeList: action.payload.data,
        };
      }
      case "GET_VISITOR_TYPE_FAILURE": {
        return {
          ...state,
          getVisitorTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_GET_VISITOR_TYPE": {
        return {
          ...state,
          getVisitorTypeSuccess: false,
          getVisitorTypeFailure: false,
          getVisitorTypeList: [],
          errorMessage: false
        };
      }
      
      case "CREATE_VISITOR_TYPE_SUCCESS": {             
        return {
          ...state,
          createVisitorTypeSuccess: true,
          createVisitorTypeData: action.payload.data,
        };
      }
      case "CREATE_VISITOR_TYPE_FAILURE": {
        return {
          ...state,
          createVisitorTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_CREATE_VISITOR_TYPE": {
        return {
          ...state,
          createVisitorTypeSuccess: false,
          createVisitorTypeFailure: false,
          createVisitorTypeData: false,
          errorMessage: false         
        };
      }
  
      case "UPDATE_VISITOR_TYPE_SUCCESS": {             
        return {
          ...state,
          updateVisitorTypeSuccess: true,
          updateVisitorTypeData: action.payload.data,
        };
      }
      case "UPDATE_VISITOR_TYPE_FAILURE": {
        return {
          ...state,
          updateVisitorTypeFailure: true,
          errorMessage: action.errorMessage
        };
      }
      case "RESET_UPDATE_VISITOR_TYPE": {
        return {
          ...state,
          updateVisitorTypeSuccess: false,
          updateVisitorTypeFailure: false,
          updateVisitorTypeData: false,
          errorMessage: false         
        };
      }
      default: {
        return {
          ...state,
        }
      }
    }
  }
  
  export default VisitorTypeReducer
  
  