import AuthGuard from "./auth/AuthGuard";
import { createBrowserRouter, redirect } from "react-router-dom";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import dataTableRoute from "./views/dataTable/dataTableRoute";
import invoiceRoutes from "./views/app/invoice/invoiceRoutes";
import inboxRoutes from "./views/app/inbox/inboxRoutes";
import chatRoutes from "./views/app/chat/chatRoutes";
import calendarRoutes from "./views/app/calendar/calendarRoutes";
import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
import contactRoutes from "./views/app/contact/contactRoutes";
import Error404 from "./views/sessions/Error";

import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import chartsRoute from "./views/charts/chartsRoute";
import departmentRoutes from "./views/masters/department/department-route";
import designationRoutes from "./views/masters/designation/designation-route";
import employeeRoutes from "./views/employee/employeeRoutes";
import shiftRoutes from "./views/masters/shift/shift-route";
import qualificationRoutes from "./views/masters/qualification/qualification-route";
import holidaysRoutes from "./views/holidays/holidays-route";
import leaveSettingsRoutes from "./views/leave-settings/leave-settings-route";
import leaveTypeRoutes from "./views/masters/leave-type/leave-type-route";
import employeeLeaveRoutes from "./views/employee-leave/employee-leave-route";
import EmployeeAttendanceReportRoutes from "./views/report/employeeAttendanceReport/employee-attendance-report-route";
import proofTypeRoutes from "./views/masters/proof-type/proof-type-route";
import employeePromotionRoutes from "./views/employee-promotion/employee-promotion-route";
import admissionRoutes from "./views/student-admission/admission-route";
import StudentAttendanceRoutes from "./views/student-attendance/studentAttendance-route";
import batchRoutes from "./views/masters/batch/batch-route";
import classRoutes from "./views/masters/class/class-route";
import sectionRoutes from "./views/masters/section/section-route";
import courseRoutes from "./views/masters/course/course-route";
import examTypeRoutes from "./views/masters/exam-type/exam-type-route";
import SubjectRoutes from "./views/masters/subject/subjectRoute";
import PeriodTimeRoutes from "./views/masters/period-time/period-time-route";
import homeWorkTypeRoutes from "./views/masters/home-work-type/home-work-type-route";
import enrollmentRoutes from "./views/masters/enrollment/enrollment-route";
import gradeRoutes from "./views/masters/grade/grade-route";
import markSheetRoutes from "./views/marksheet/marksheet-route";
import subjectEnrollmentRoutes from "./views/subjectEnrollment/subject-enrollment-route";
import subjectTeachersRoutes from "./views/subject-teachers/subject-teachers-route";
import timeTableRoutes from "./views/time-table/time-table-route";
import roleRoutes from "./views/masters/role/role-route";
import accessRoutes from "./views/masters/access/access-route";
import feesTypeRoutes from "./views/masters/fees-type/fees-type-route";
import feesEnrollmentRoutes from "./views/fees-enrollment/fees-enrollment-route";
import studentFeesPaymentRoutes from "./views/student-fees-payment/student-fees-payment-route";
import UnitRoutes from "./views/unit/unit-route";
import PagePermissionRoutes from "./views/page-permission/page-permission-route";
import circularRoutes from "./views/circular/circular-route";
import schoolInfoRoutes from "./views/school-info/school-info-route";
import KnowledgeMaterialRoutes from "./views/knowledge-material/knowledge-material-route";
import EventRoutes from "./views/event/event-route";
import EnquiryRoutes from "./views/enquiry/enquiry-route";
import DailyExamMarkSheetRoutes from "./views/daily-exam-marksheet/daily-exam-marksheet-route";
import UnitTypeRoutes from "./views/masters/unit-type/unit-type-route";
import studentLeaveRoutes from "./views/student-leave/student-leave-route";
import studentListRoutes from "./views/student-list/student-list-route";
import BookRoutes from "./views/masters/book/book-route";
import BookTypeRoutes from "./views/masters/book-type/book-type-route";
import KnowledgeBookListRoutes from "./views/masters/knowledge-book/knowledge-book-route";
import SyllabusRoutes from "./views/syllabus/syllabus-route";
import QuizRoutes from "./views/quiz/quiz-route";
import CreateQuizRoutes from "./views/quiz/create-quiz/create-quiz-route";
import PettyCashRoutes from "./views/petty-cash/petty-cash-route";
import visitorTypeRoutes from "./views/visitor-type/visitor-type-route";
import visitorRoutes from "./views/visitor/visitor-route";
import visitorLogRoutes from "./views/visitor-log/visitor-log-route";


const routes = createBrowserRouter([
  {
    element: <AuthGuard />,
    children: [
      ...dashboardRoutes,
      ...chartsRoute,
      ...dataTableRoute,
      ...invoiceRoutes,
      ...inboxRoutes,
      ...calendarRoutes,
      ...taskManagerRoutes,
      ...contactRoutes,
      ...chatRoutes,
      ...proofTypeRoutes,

      ...departmentRoutes,
      ...designationRoutes,
      ...employeeRoutes,
      ...shiftRoutes,
      ...qualificationRoutes,
      ...holidaysRoutes,
      ...leaveSettingsRoutes,
      ...leaveTypeRoutes,
      ...employeeLeaveRoutes,
      ...EmployeeAttendanceReportRoutes,
      ...employeePromotionRoutes,
      ...gradeRoutes,
      ...markSheetRoutes,

      ...admissionRoutes,
      ...StudentAttendanceRoutes,
      ...batchRoutes,
      ...classRoutes,
      ...sectionRoutes,
      ...courseRoutes,
      ...examTypeRoutes,
      ...SubjectRoutes,
      ...PeriodTimeRoutes,
      ...homeWorkTypeRoutes,
      ...enrollmentRoutes,
      ...subjectEnrollmentRoutes,
      ...subjectTeachersRoutes,
      ...timeTableRoutes,
      ...roleRoutes,
      ...accessRoutes,
      ...feesTypeRoutes,
      ...feesEnrollmentRoutes,
      ...studentFeesPaymentRoutes,
      ...UnitRoutes,
      ...PagePermissionRoutes,
      ...circularRoutes,
      ...schoolInfoRoutes,
      ...circularRoutes ,
      ...KnowledgeMaterialRoutes ,
      ...EventRoutes,
      ...EnquiryRoutes,
      ...DailyExamMarkSheetRoutes,
      ...UnitTypeRoutes,
      ...studentListRoutes,
      ...studentLeaveRoutes,
      ...BookRoutes,
      ...BookTypeRoutes,
      ...KnowledgeBookListRoutes ,
      ...SyllabusRoutes ,
     ...QuizRoutes ,
     ...CreateQuizRoutes ,
     ...PettyCashRoutes ,
     ...visitorTypeRoutes,
     ...visitorRoutes,
     visitorLogRoutes,

    ],
  },
  ...sessionsRoutes,
  {
    path: "/",
    exact: true,
    loader: () => redirect("/dashboard"),
  },
  {
    path: "*",
    element: <Error404 />,
  },
]);

export default routes;
