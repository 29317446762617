import { lazy } from "react";

const VisitorList = lazy(() => import("./visitorList"));

const visitorRoutes = [
  {
    path: "/visitor",
    element: <VisitorList />,
  }
];

export default visitorRoutes;
